/*  typography */
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Roboto:300,400,500,700');

@font-face {
  font-family: 'BwNistaInt-xBd';
  src: url("../plugins/fonts/BwNistaInt-xBd.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

body {
  line-height: 1.2;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: $text-color;
}

p, .paragraph {
  font-weight: 400;
  color: $text-color;
  font-size: 15px;
  line-height: 1.8;
  font-family: $primary-font;
  &-lg{
    font-size: 22px;
  }
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-family: $secondary-font;
  line-height: 1.2;
}

h1, .h1{
  font-size: 80px;
  @include desktop {
    font-size: 65px;
  }
}

h2, .h2{
  font-size: 60px;
  @include desktop {
    font-size: 50px;
  }
}

h3, .h3{
  font-size: 40px;
}

h4, .h4{
  font-size: 22px;
}

h5, .h5{
  font-size: 18px;
}

h6, .h6{
  font-size: 14px;
}
